import { createRouter, createWebHistory } from 'vue-router'

const viewComponents = {
  blankLayout: {
    type: 0,
    component: () => import(/* webpackChunkName: "blankLayout" */ '@/layout/BlankLayout.vue')
  },
  // 账户余额
  accountBalance: {
    type: 1,
    component: () => import(/* webpackChunkName: "accountBalance" */ '@/views/accountBalance/index.vue')
  },
  // 登录
  login: {
    type: 1,
    component: () => import(/* webpackChunkName: "login" */ '@/views/myUser/Login.vue')
  },
  // 注册
  register: {
    type: 1,
    component: () => import(/* webpackChunkName: "register" */ '@/views/myUser/register.vue')
  },
  // 忘记密码
  forgetPassword: {
    type: 1,
    component: () => import(/* webpackChunkName: "password" */ '@/views/myUser/forgetPassword.vue')
  },
  my: {
    type: 1,
    component: () => import(/* webpackChunkName: "my" */ '@/views/my')
  },
  // 提现与充值
  balanceCard: {
    type: 2,
    component: () => import(/* webpackChunkName: "balanceCard" */ '@/views/accountBalance/balanceCard.vue')
  },
  // 首页
  home: {
    type: 1,
    component: () => import(/* webpackChunkName: "home" */ '@/views/home')
  },
  // 套餐详情
  shopDetail: {
    type: 2,
    component: () => import(/* webpackChunkName: "shopDetail" */ '@/views/shopDetail')
  },
  // 购物车
  shopCar: {
    type: 1,
    component: () => import(/* webpackChunkName: "shopCar" */ '@/views/shopCar')
  },
  // 元宝
  yuanBao: {
    type: 2,
    component: () => import(/* webpackChunkName: "yuanBao" */ '@/views/yuanBao')
  },
  // 账户管理
  accountManagement: {
    type: 2,
    component: () => import(/* webpackChunkName: "accountManagement" */ '@/views/accountManagement')
  },
  // 个人信息
  personalInformation: {
    type: 2,
    component: () =>
      import(/* webpackChunkName: "accountManagement" */ '@/views/accountManagement/personalInformation.vue')
  },
  // 修改昵称
  editNickName: {
    type: 2,
    component: () => import(/* webpackChunkName: "accountManagement" */ '@/views/accountManagement/editNickName.vue')
  },
  // 设置支付密码
  editPayPassword: {
    type: 2,
    component: () => import(/* webpackChunkName: "accountManagement" */ '@/views/accountManagement/editPayPassword.vue')
  },
  // 门店订单详情
  storeOrderDetails: {
    type: 2,
    component: () => import(/* webpackChunkName: "payOrder" */ '@/views/storeOrderDetails')
  },
  // 门店销售分成
  storeDistributionPerformance: {
    type: 1,
    component: () => import(/* webpackChunkName: "accountBalance" */ '@/views/storeDistributionPerformance/index.vue')
  },
  // 分销业绩
  distributionPerformance: {
    type: 2,
    component: () => import(/* webpackChunkName: "payOrder" */ '@/views/distributionPerformance')
  },
  // 聚宝盆
  aTreasureTrove: {
    type: 2,
    component: () => import(/* webpackChunkName: "payOrder" */ '@/views/aTreasureTrove')
  },
  // 支付成功
  paySuccess: {
    type: 3,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/paySuccess')
  },
  // 余额转账
  transfer: {
    type: 3,
    component: () => import(/* webpackChunkName: "transfer" */ '@/views/accountBalance/transfer.vue')
  },
  // 支付成功
  inviteFriends: {
    type: 2,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/inviteFriends')
  },
  // 兑换余额
  exchange: {
    type: 3,
    component: () => import(/* webpackChunkName: "exchange" */ '@/views/yuanBao/exchange.vue')
  },
  // 关联门店
  associatedStores: {
    type: 2,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/associatedStores/index')
  },
  // 我的金豆
  myGoldenBean: {
    type: 2,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/myGoldenBean')
  }
}

// 配置路由
const routes = [
  {
    path: '/',
    component: viewComponents.blankLayout.component,
    children: [
      {
        path: 'my',
        component: viewComponents.my.component
      },
      {
        path: 'home',
        component: viewComponents.home.component
      },
      {
        path: 'shopCar',
        component: viewComponents.shopCar.component
      }
    ]
  },
  {
    path: '/accountBalance',
    component: viewComponents.accountBalance.component
  },
  {
    path: '/login',
    name: 'login',
    component: viewComponents.login.component
  },
  {
    path: '/register/:code?',
    name: 'register',
    component: viewComponents.register.component
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: viewComponents.forgetPassword.component
  },
  {
    path: '/balanceCard',
    component: viewComponents.balanceCard.component
  },
  {
    path: '/shopDetail/:id',
    component: viewComponents.shopDetail.component
  },
  {
    path: '/yb',
    component: viewComponents.yuanBao.component
  },
  {
    path: '/accountManagement',
    component: viewComponents.accountManagement.component
  },
  {
    path: '/inviteFriends',
    component: viewComponents.inviteFriends.component
  },
  {
    path: '/storeOrderDetails',
    component: viewComponents.storeOrderDetails.component
  },
  {
    path: '/aTreasureTrove',
    component: viewComponents.aTreasureTrove.component
  },
  {
    path: '/storeDistributionPerformance',
    component: viewComponents.storeDistributionPerformance.component
  },
  {
    path: '/distributionPerformance',
    component: viewComponents.distributionPerformance.component
  },
  {
    path: '/paySuccess/:id',
    component: viewComponents.paySuccess.component
  },
  {
    path: '/transfer',
    component: viewComponents.transfer.component
  },
  {
    path: '/exchange/:type',
    component: viewComponents.exchange.component
  },
  {
    path: '/associatedStores',
    component: viewComponents.associatedStores.component
  },
  {
    path: '/myGoldenBean',
    component: viewComponents.myGoldenBean.component
  },
  {
    path: '/personalInformation',
    component: viewComponents.personalInformation.component
  },
  {
    path: '/editNickName',
    component: viewComponents.editNickName.component
  },
  {
    path: '/editPayPassword',
    component: viewComponents.editPayPassword.component
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
