import userApi from '@/api/user'

export default {
  namespaced: true,
  state() {
    return {
      dataList: [],
      totalItem: 0
    }
  },
  mutations: {
    setData(state, paylod) {
      const { data, totalItem } = paylod
      state.dataList = [...state.dataList, ...data]
      state.totalItem = totalItem
    },
    restList(state) {
      state.dataList = []
      state.totalItem = 0
    },
    setPrice(sate, paylod) {
      console.log(sate, paylod, 'sate, paylod');
      // const userData = JSON.parse(localStorage.getItem('user-data'))
      // userData.balance = paylod.userGoldsNum
      // localStorage.setItem('user-data', JSON.stringify(userData))
      // sate.info.price = paylod
    }
  },
  actions: {
    async getData({ commit }, paylod) {
      const res = await userApi.getSummary(paylod)
      localStorage.setItem('user-data', JSON.stringify(res.data))
    }
  }
}
