/*
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-08-03 15:30:03
 */
// 路由配置
import { wechatAuth } from '@/utils/wechatShare.js'
import router from '@/router/config-router'
import store from '@/store/index'
// import { useRouter, useRoute } from 'vue-router'
// import { init } from './utils/wxPay'
import { getVersion } from '@/api/version'
import { Toast } from 'vant'
import userApi from '@/api/user'

// 白名单
const whiteList = ['login', 'register', 'forgetPassword'] // 无需登录的页面
// const $router = useRouter()
// 守卫
router.beforeEach((to, from, next) => {


  const userId = localStorage.getItem('hg-org-userId')
  if (userId) {
    // 路由切换时进行版本获取
    getVersion()
      .then((res) => {
        let { code, data, success } = res
        if (code === 0 && success === true) {
          if (localStorage.getItem('versionInfo') !== data.versionInfo) {
            localStorage.setItem('flag', 1)
            localStorage.setItem('versionInfo', data.versionInfo)
          } else {
            localStorage.setItem('flag', 0)
          }
        } else {
          return
        }
      })
      .catch((error) => {
        return
      })
  }
  // 判断本地缓存
  if (userId) {
    if (!sessionStorage.getItem('beforRouter')) {
      sessionStorage.setItem('beforRouter', JSON.stringify(window.location.href))
    }
    console.log('--------------', to, from)
    // 身份选择返回
    if (to.path === '/login' || to.path == '/') {
      if (to.path === '/forgetPassword') {
        return next()
      }
      userApi.getSummary(JSON.parse(localStorage.getItem('hg-org-userId')).userId).then((ress) => {
        localStorage.setItem('user-data', JSON.stringify(ress.data))
        next('/my')
        return
      })
      // next('/home')
      // next('/my')
      return
    }
    const cacheList = to.meta.cacheList
    if (cacheList) {
      if (cacheList.indexOf(from.name) > -1) {
        store.commit('keepAlive/ADD_CACHED_VIEW', to)
      } else {
        store.commit('keepAlive/DEL_CACHED_VIEW', to)
      }
    }

    if (to.query.order) {
      console.log(5050)
      localStorage.setItem('hg-org-userId', JSON.parse(to.query?.order).userId)
      localStorage.setItem('currentOrder', JSON.parse(to.query?.order).currentOrder)
    }
    next()
  } else {
    if (to.query.order) {
      localStorage.setItem('hg-org-userId', JSON.parse(to.query?.order).userId)
      localStorage.setItem('currentOrder', JSON.parse(to.query?.order).currentOrder)
      return next()
    }

    if (to.query.userInfo) {
      // console.log(to.query.userInfo, '微信快捷用户未注册')
      if (to.query.userInfo === 'no') {
        Toast('微信账户未注册,请前往注册后登录或先验证码登录')
        return next({ path: '/login' })
      }

      localStorage.setItem('hg-org-userId', to.query?.userInfo)
      // return next()
      userApi.getSummary(JSON.parse(to.query?.userInfo).userId).then((ress) => {
        localStorage.setItem('user-data', JSON.stringify(ress.data))
        return next('/my')
      })
    }

    if (whiteList.includes(to.name)) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

router.afterEach((to) => {
  var ua = navigator.userAgent.toLowerCase()
  var isWeixin = ua.indexOf('micromessenger') != -1
  console.log(isWeixin, 'wx ')
  if (isWeixin && !whiteList.includes(to.name)) {
    const userId = localStorage.getItem('hg-org-userId')

    let authUrl = `${window.location.href}`
    if (window.__wxjs_is_wkwebview) {
      // IOS
      if (window.entryUrl == '' || window.entryUrl == undefined) {
        window.entryUrl = authUrl // 将后面的参数去除
      }
      if (userId && !to.path.includes('identitySwitching')) {
        wechatAuth('ios', authUrl)
      }
    } else {
      // 安卓
      setTimeout(function () {
        if (userId && !to.path.includes('identitySwitching')) {
          wechatAuth('android', authUrl)
        }
      }, 500)
    }
  }
})

/* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})
